$(document).ready(function () {
    // Select all elements with the class "newsletter__wrapper" or "footer__newsletter"
    const newsletters = $('.newsletter__wrapper, .footer__newsletter');

    newsletters.each(function () {
        const newsletter = $(this);
        const newsletterForm = newsletter.find('form')
        const customCheckbox = newsletter.find('.custom-checkbox');
        const newsletterInput = customCheckbox.find('input');
        const newsletterInputEmail = newsletterForm.find('input[type="email"]')
        const submitButton = newsletter.find('.btn--submit');

        newsletterInputEmail.on('input', function (e) {
            const isEmailValid = isValidEmail(e.target.value)

            if (isEmailValid && newsletterInput.is(':checked')) {
                submitButton.removeClass('disabled');
                submitButton.removeAttr('disabled');
            } else {
                submitButton.attr('disabled')
                submitButton.addClass('disabled');
            }
        })

        // Listen for changes in the checkbox state
        newsletterInput.on('change', function () {
            const isEmailValid = isValidEmail(newsletterInputEmail.val())
            // Enable/disable the submit button based on checkbox state
            if (newsletterInput.is(':checked') && isEmailValid) {
                submitButton.removeClass('disabled');
                submitButton.removeAttr('disabled');
                customCheckbox.removeClass('error');
            } else {
                submitButton.attr('disabled')
                submitButton.addClass('disabled');
            }
        });

        // Prevent clicking the button if it has "btn--sent" or "btn--sending" class
        submitButton.on('click', function (e) {
            e.preventDefault();

            const newsletterServiceUrl = newsletterForm.attr('action')
            const marginClass = newsletter.hasClass('footer__newsletter') ? 'mt-4' : ''

            if (!newsletterServiceUrl) {
                newsletterForm.replaceWith(`<p class="typography-h4 text-danger ${marginClass}">${newsletterForm.attr('data-error-message')}</p>`)
                return
            }

            const contactList = JSON.parse(newsletterForm.attr('data-contact-list'))
            const email = newsletterForm.find('input[name="newsletter_email"]').val()

            if (submitButton.hasClass('disabled')) customCheckbox.addClass('error');

            if (!submitButton.hasClass('btn--sending') && !submitButton.hasClass('btn--sent') && !submitButton.hasClass('disabled') && contactList && email) {
                submitButton.toggleClass('disabled')
                submitButton.toggleClass('btn--sending')
                fetch(newsletterServiceUrl, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ list_ids: contactList, contacts: [{ email }] })
                }).then(response => response.json()).then(data => {
                    let message = ''
                    let errorClass = ''

                    if (data.job_id) {
                        message = newsletterForm.attr('data-success-message')
                    } else {
                        message = newsletterForm.attr('data-error-message')
                        errorClass = 'text-danger'
                    }

                    submitButton.addClass('btn--sent')
                    newsletterForm.replaceWith(`<p class="typography-h4 ${marginClass} ${errorClass}">${message}</p>`)
                })
            }
        });

        // ------------------------------ //
        // Handle the newsletter checkbox
        // ------------------------------ //
        const a = customCheckbox.find('a')

        // Toggle the checkbox by clicking in the label text (or using a keyboard)
        customCheckbox.on('click keypress', function (e) {
            if (!$(e.target).is('a') && (e.type === 'click' || (e.type === 'keypress' && (e.key === ' ' || e.key === 'Enter')))) {
                const isEmailValid = isValidEmail(newsletterInputEmail.val())
                newsletterInput.attr("checked", !newsletterInput.attr("checked"))

                if (newsletterInput.is(':checked') && isEmailValid) {
                    submitButton.removeClass('disabled');
                    submitButton.removeAttr('disabled');
                    customCheckbox.removeClass('error');
                } else {
                    submitButton.attr('disabled')
                    submitButton.addClass('disabled');
                }
            }
        })

        // The link will work as expected, and the click event won't propagate to the checkbox.
        a.click(function (e) {
            e.stopPropagation()
        })

        a.keypress(function (e) {
            if (e.key === ' ' || e.key === 'Enter') {
                // Allow the link to be activated using the keyboard.
            }
        })
    });
});

function isValidEmail (email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    return re.test(String(email).toLowerCase())
}
